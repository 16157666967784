import base32 from 'base32';
import { MessageBarType, createTheme } from "office-ui-fabric-react";
import { getUser } from "../services/auth";
import { RetornaCNPJormatado, RetornaCPFFormatado } from './Format';

export function GetCeaTheme() {
  return createTheme({
    palette: {
      themePrimary: '#0f62fe',
      themeLighterAlt: '#f7f3fb',
      themeLighter: '#dfd0ef',
      themeLight: '#c5abe2',
      themeTertiary: '#9265c5',
      themeSecondary: '#6931aa',
      themeDarkAlt: '#0353e9',
      themeDark: '#0353e9',
      themeDarker: '#0353e9',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    }
  });
}


export const isProduction = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("main")) {
    return true;
  }

  return false;
}

export const CeaEnvironmentName = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return "UAT"
  }
  else if (reactEnv.includes("dev")) {
    return "DEV"
  }
  else {
    return 'PROD';
  }
}

export const CeaEnvironment = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return " [UAT]"
  }
  else if (reactEnv.includes("dev")) {
    return " [DEV]"
  }
  else {
    return '';
  }
}

export const CeaEnvironmentClass = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return "-uat"
  }
  else if (reactEnv.includes("dev")) {
    return "-dev"
  }
  else {
    return '';
  }
}

export const CeaEnvironmentBack = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return "rgb(255 232 232)"
  }
  else if (reactEnv.includes("dev")) {
    return "(255 254 232)"
  }
  else {
    return '#fff';
  }
}

export const CeaEnvironmentAltColor = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return "red"
  }
  else if (reactEnv.includes("dev")) {
    return "yellow"
  }
  else {
    return '#d0e2ff';
  }
}

export const CeaEnvironmentHeaderColor = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return "#160c0c"
  }
  else if (reactEnv.includes("dev")) {
    return "#1e1c11"
  }
  else {
    return "rgb(24 24 27)";
  }
}

export const CeaEnvironmentHoverColor = () => {

  var reactEnv = process.env.REACT_APP_CEA_ENV.toLowerCase();

  if (reactEnv.includes("uat")) {
    return "#380a0a"
  }
  else if (reactEnv.includes("dev")) {
    return "#38330a"
  }
  else {
    return "#070707";
  }
}

export const genNewKey = () => {
  var bytes = new Uint8Array(30);
  window.crypto.getRandomValues(bytes);
  return `${base32.encode(bytes)}`
}

export const jwtDecode = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const headerConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const headerConfigPost = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const cpHeaderConfigPost = {
  headers: {
    "Content-Type": "application/json",
    "Flow-Key": "55944C7FF6474E558DA8293DA1298509E144E5D532CD436EA147064FCE2D1473"
  },
};

export const headerConfigFileDownload = {
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "blob",
};

export const ColDefs = {
  flex: 0,
  resizable: true,
  filter: true,
  floatingFilter: false,
};

export const ColDefsWithFlex = {
  flex: 1,
  resizable: true,
  filter: true,
  floatingFilter: false,
};

export const iconMenuHeader = {
  fontSize: 16,
  marginLeft: 10,
  //padding: 0,
  //verticalAlign: "middle",
  //paddingLeft: 0,
  color: "#ffffff",
};

export const iconHeader = {
  fontSize: 12,
  padding: 0,
  verticalAlign: "middle",
  paddingLeft: 0,
  color: "#ffffff",
};

export const userProfiles = [
  { key: "CONSULTA", text: "CONSULTA" },
  { key: "USER", text: "USER (OPERAÇÃO)" },
];

export const displayUsuario = () => {
  var user = getUser();
  var fullName = `${user.Name} ${user.Surname}`;
  if (fullName.length > 20) {
    var result = fullName.substring(0, 17);
    return `${result}...`;
  } else {
    return fullName;
  }
};

export const MeioDePagamentoIcon = (props) => {
  var _array = [];
  switch (props.value) {
    case 0: //boleto
      if (props.data.HasBoleto) {
        _array.push("GenericScan", "Boleto");
      } else {
        _array.push("BoxMultiplySolid", "Boleto indisponível");
      }
      break;
    case 1:
      _array.push("M365InvoicingLogo", "Outro (TEC/DOC/Transf.)");
      break;
    default:
      _array.push("UnknownSolid", "Desconhecido");
      break;
  }

  return _array;
};

export const messageBarType = (typeString) => {
  var result = MessageBarType.info;

  switch (typeString) {
    case "info":
      break;
    case "success":
      result = MessageBarType.success;
      break;
    case "warning":
      result = MessageBarType.warning;
      break;
    case "severeWarning":
      result = MessageBarType.severeWarning;
      break;
    case "error":
      result = MessageBarType.error;
      break;
    case "blocked":
      result = MessageBarType.blocked;
      break;
    default:
      break;
  }

  return result;
};

export const bancosPRD = [
  {
    key: 0,
    text: "000 - Cartório",
  },
  {
    key: 1,
    text: "001 - Banco do Brasil",
  },
  {
    key: 25,
    text: "025 - Banco Alfa",
  },
  {
    key: 33,
    text: "033 - Santander",
  },
  {
    key: 94,
    text: "094 - Finaxis",
  },
  {
    key: 104,
    text: "104 - Caixa Econômica Federal",
  },
  {
    key: 107,
    text: "107 - BOCOM BBM S.A.",
  },
  {
    key: 208,
    text: "208 - Banco BTG Pactual.",
  },
  {
    key: 237,
    text: "237 - Bradesco",
  },
  {
    key: 246,
    text: "246 - Banco ABC",
  },
  {
    key: 269,
    text: "269 - Banco HSBC",
  },
  {
    key: 318,
    text: "318 - Banco BMG S.A.",
  },
  {
    key: 341,
    text: "341 - Itaú S.A.",
  },
  {
    key: 376,
    text: "376 - JP Morgan",
  },
  {
    key: 422,
    text: "422 - Banco Safra",
  },
  {
    key: 456,
    text: "465 - Tokyo MUFG",
  },
  {
    key: 745,
    text: "745 - Citibank",
  },
  {
    key: 655,
    text: "655 - Banco Votorantim S.A.",
  },
  {
    key: 756,
    text: "756 - Banco Cooperativo do Brasil S.A.",
  },
];

export const GetBancoPrefix = (banco) => {
  switch (banco) {
    case 0:
      return "CA";
    case 1:
      return "BB";
    case 25:
      return "AF";
    case 33:
      return "SA";
    case 94:
      return "FX";
    case 104:
      return "CX";
    case 107:
      return "BO";
    case 208:
      return "BT";
    case 237:
      return "BD";
    case 246:
      return "AB";
    case 269:
      return "HS";
    case 318:
      return "BG";
    case 341:
      return "IT";
    case 376:
      return "IT";
    case 422:
      return "SF";
    case 456:
      return "MU";
    case 745:
      return "CT";
    case 655:
      return "BV";
    case 756:
      return "SB";
    case "0":
      return "CA";
    case "1":
      return "BB";
    case "25":
      return "AF";
    case "33":
      return "SA";
    case "94":
      return "FX";
    case "104":
      return "CX";
    case "107":
      return "BO";
    case "208":
      return "BT";
    case "237":
      return "BD";
    case "246":
      return "AB";
    case "269":
      return "HS";
    case "318":
      return "BG";
    case "341":
      return "IT";
    case "376":
      return "IT";
    case "422":
      return "SF";
    case "456":
      return "MU";
    case "745":
      return "CT";
    case "655":
      return "BV";
    case "756":
      return "SB";
    default:
      return "CA";
  }
};

export const BancoCodigo = (bancoNum) => {
  var bancoCodigo = "";
  switch (bancoNum) {
    case 0:
      bancoCodigo = "000";
      break;
    case 1:
      bancoCodigo = "001";
      break;
    case 25:
      bancoCodigo = "025";
      break;
    case 33:
      bancoCodigo = "033";
      break;
    case 94:
      bancoCodigo = "094";
      break;
    case 655:
      bancoCodigo = "655";
      break;
    case "0":
      bancoCodigo = "000";
      break;
    case "1":
      bancoCodigo = "001";
      break;
    case "33":
      bancoCodigo = "033";
      break;
    case "94":
      bancoCodigo = "094";
      break;
    case "655":
      bancoCodigo = "655";
      break;
    default:
      bancoCodigo = bancoNum;
      break;
  }

  return bancoCodigo;
};


export const BancoNum = (bancoNum) => {
  var _bancoNum = "";
  switch (bancoNum) {
    case "0":
      _bancoNum = 0;
      break;
    case "1":
      _bancoNum = 1;
      break;
    case "25":
      _bancoNum = 25;
      break;
    case "33":
      _bancoNum = 33;
      break;
    case "94":
      _bancoNum = 94;
      break;
    case "104":
      _bancoNum = 104;
      break;
    case "107":
      _bancoNum = 107;
      break;
    case "208":
      _bancoNum = 208;
      break;
    case "237":
      _bancoNum = 237;
      break;
    case "246":
      _bancoNum = 246;
      break;
    case "269":
      _bancoNum = 269;
      break;
    case "318":
      _bancoNum = 318;
      break;
    case "341":
      _bancoNum = 341;
      break;
    case "376":
      _bancoNum = 376;
      break;
    case "422":
      _bancoNum = 422;
      break;
    case "456":
      _bancoNum = 456;
      break;
    case "745":
      _bancoNum = 745;
      break;
    case "655":
      _bancoNum = 655;
      break;
    case "756":
      _bancoNum = 756;
      break;
    case 0:
    case 1:
    case 25:
    case 33:
    case 94:
    case 104:
    case 107:
    case 208:
    case 237:
    case 246:
    case 269:
    case 318:
    case 341:
    case 376:
    case 422:
    case 456:
    case 745:
    case 655:
    case 756:
      _bancoNum = bancoNum;
      break;
    default:
      _bancoNum = 0;
      break;
  }

  return _bancoNum;
};

export const BancoNome = (bancoNum) => {
  var bancoNome = "";
  switch (bancoNum) {
    case 0:
      bancoNome = "Cartório";
      break;
    case 1:
      bancoNome = "Banco do Brasil";
      break;
    case 25:
      bancoNome = "Banco Alfa S.A.";
      break;
    case 33:
      bancoNome = "Banco Santander";
      break;
    case 94:
      bancoNome = "Banco Finaxis";
      break;
    case 104:
      bancoNome = "Caixa E. F.";
      break;
    case 107:
      bancoNome = "Banco BOCOM BBM";
      break;
    case 208:
      bancoNome = "Banco BTG Pactual";
      break;
    case 237:
      bancoNome = "Banco Bradesco";
      break;
    case 246:
      bancoNome = "Banco ABC";
      break;
    case 269:
      bancoNome = "Banco HSBC";
      break;
    case 318:
      bancoNome = "Banco BMG S.A.";
      break;
    case 341:
      bancoNome = "Banco Itaú";
      break;
    case 376:
      bancoNome = "Banco JP Morgan";
      break;
    case 422:
      bancoNome = "Banco Safra";
      break;
    case 456:
      bancoNome = "Banco MUFG";
      break;
    case 745:
      bancoNome = "Citibank S.A.";
      break;
    case 655:
      bancoNome = "Banco Votorantim S.A.";
      break;
    case 756:
      bancoNome = "Banco Cooperativo do Brasil S.A.";
      break;
    case "0":
      bancoNome = "Cartório";
      break;
    case "1":
      bancoNome = "Banco do Brasil";
      break;
    case "25":
      bancoNome = "Banco Alfa S.A.";
      break;
    case "33":
      bancoNome = "Banco Santander";
      break;
    case "94":
      bancoNome = "Banco Finaxis";
      break;
    case "104":
      bancoNome = "Caixa E. F.";
      break;
    case "107":
      bancoNome = "Banco BOCOM BBM";
      break;
    case "208":
      bancoNome = "Banco BTG Pactual";
      break;
    case "237":
      bancoNome = "Banco Bradesco";
      break;
    case "246":
      bancoNome = "Banco ABC";
      break;
    case "269":
      bancoNome = "Banco HSBC";
      break;
    case "318":
      bancoNome = "Banco BMG S.A.";
      break;
    case "341":
      bancoNome = "Banco Itaú";
      break;
    case "376":
      bancoNome = "Banco JP Morgan";
      break;
    case "422":
      bancoNome = "Banco Safra";
      break;
    case "456":
      bancoNome = "Banco MUFG";
      break;
    case "745":
      bancoNome = "Citibank S.A.";
      break;
    case "655":
      bancoNome = "Banco Votorantim S.A.";
      break;
    case "756":
      bancoNome = "Banco Cooperativo do Brasil S.A.";
      break;
    default:
      bancoNome = "Cartório";
      break;
  }

  return bancoNome;
};

export const BancoIconBase64 = (bancoNum) => {
  var bancoIcon = "";
  switch (bancoNum) {
    case 0:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUNJREFUOE9tU8FRBDEMk560dZRACTygi2y6gB5o4WiLp28s2Ulg2MfujmPLkuwQAAgCCIQ/eir2lDEGfyIP8jyfynFevaJACuONxMeRl0XvAD/zhwQiDxO8MLvtHYGbQQ46q4nafYN4NgtnrT+G2e3SFCLy1aBOWiuTDLMsdTrxLIaPSrPBMuOAlFeHe13+v4gGOA0vG+ICMGw/aYfcrGRlVExKZeZNBK728SJjuC5lmSrF1QNsAImlzJshACHzImJUSzJW6/ZPeYrLer0nkHXmegExjFWTkdOLcUutmDILwFuRSKOVHxvQW9OwrSjpTEASDABi1MB3t57QmmBZYjkzBOBJLgC1lEbx2KRsXEbal0kzsNLSX6u4Z+aFX8Pr61NL1Yu3cs6r8esq/VnlvbHpwGsAL/tWnn99F3ozvca2TbGvB0GdsXmb9kprAAAAAElFTkSuQmCC";
      break;
    case 1:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAJwSURBVHjaTJJbSFNxHMe//7MzN22RUZqTIBBGGgWWDwPdgxcCKRCZomVZUstrD85LSl4oTKGkQlmSYMFUmikrFqUZahJp5SVQiIjUrKzMW5vXs+2c8+vFsC+fh+/Dl+/Th5E4CLEfDJshgAEKERK/2QlgBAAKPQ+pG8LVrTUHKDH3SxG4S4IMSAAPAO4NqDTZHKACwyZqSExR3XEmtMhR2pqz7lXDD9Pz2nRL+cuP++HD+K1LNcY+60pbs77MBWXEdDf1Hev7EG4x1TveGYYmwiqNC5AVIHcNrWDNqb7Vnqo9/WhHSmf3gJ4IQ+MHYkrq/FOeld+/8PPHbtoACQU8GHmIMzWU2Abi/DXLHJOHp0IF0aeyI6P+7B3HSNS1tnPjX0Ns+eV+fhIPYkomlyU3A5ieDzLFdr6dCK3rMuYcffL600HHSKTR0FuRbPXlJRAHcteQE+QFiWjpig/LajYUWuy9sUlVVcHp9tsPT5AbJIGcIMHMA4ACM7OBtY6Tj4cNSfpXIQGz5pbs8H2TN9KabIPRo9O6iiSrbs83BvBg5Ja5Qmtee3985nF7uqEnzVJ2OdHmFflLD0xrbrVrbfucc6e9uETjI/MgplLKdzNv6oJnLM8TJ39r72XXRh0eG3x/SJQVDFSc0FaUYNP4eEEcI2/V0mL1+cYKrf+f1Mg+szXv+2JAbryjZyzCLSqvn2qMOzICDyACKjNIvvJmdK8+v7F3KIJErCxtMzdc9DW+yK0vcC5oyANaBi2DXCDBDPJUrsxCWAQJIBdoFbSOqQkNrYNWQa5/OEFCDiNvD6SnYASZ2zKOFyHyIPafmwQ++u8AoNItxauVAEEAAAAASUVORK5CYII=";
      break;
    case 25:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACTFBMVEURIUIMJDsVH0EJJDgSI0ITHEMPH0ERIT8SH0QRIEEQIEESITsXHjsKIUsOHT317s8xND8SHlERHkwQIUASHkwWIUENIVUjJyqrjRTBun0UIysOIUASIUINI0ISJiwRJjnQzpLDlQShkTg8TkIHHEIRIEMPJjcOHkUlMGhETlAkHgseHBPW2+AUKUkRH0ARIkIRHTcWIja9ydsZLGsNFk1Xa7MPHkE5T3ASIUMSIkMRIUMVH1UTJmUSLEgSLEdPgK0QLU8WK1CWp8oPHT4UJEUQIEAKHjLe5e8WKVURIEJZbMcRIVQdKk+Em8NBW5ITIkMSIEMQH0APHz83UYQLG0FKZp8LHkcOH0JMaJ8SI0YLIEpne6sLHD8UIkQUI0QXJ0fa4O0pQnUmR4irvN4uRncuTYm9yuYuRHowR3xRaJ4OHT8yTHs+YakXJkYhOGw7W50PIkhEY6obMmU9XZ4OIEUUIkUYJ0hDXpMMHT4kPW41T4USJUcrRXYwTYQPIEEnQnhLY5oJFztGX5oUI0MMHD8zT48/W5ZBWo/K1etJZp1AWpGgs91VdbRQaZu6x+ZJY6RDWJSGoNIPJEgVH0ARHj8YN3k9WpoQIUMvSn4uSoQJGzg2Uo5AW5EfMFU+VogxSIYRHjwySoBMaaEQIDo1TYYXLFEpRoJCZawMGzoWLFUyVJYOIUIfN2Y2WJwPHjsdL1o5U6IRI0MXLlYXJ0xOZZY9UoY5T4NEXZEyRngvSXpFXo45UoczSn1DWYc4UIRPY5kzS3w8V4cbL1v///9469hQAAAAAWJLR0TDimiOQgAAAAd0SU1FB+cBExMpJjByV2QAAAABb3JOVAHPoneaAAAA/klEQVQY02NgYGBgZGJmYWVj5+DkYoAAbh5ePn4BQSFhmICIqJi4hKSUNJTLICMrJ6+gqKSsoiojAxFRU9fQ1NLW0dWTgYjoGxgaGZuYmplbcIIFLK2sbWzt7B0cnZzBKixcXN3cPTy9vH18/fxl/BkYAgKDgkNCw8IjIqOiY2IDgQJx8QmJSckpqWnpGZlZQBWxFtk5uXn5WQWF/kXFJTKWDKVl5RWVVdU1tXX1DY1NzRYMLa1t7R2dXd09vX39EyZOmswwZeq06TNmzpo9Z+68+QsWLlrMsGTpsuUrVq5avWbtuvUbNm7azLBl67btO3bu2r1n775t+w8cPAQAVCxPM7SFbR4AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDEtMTlUMTk6NDE6MzcrMDA6MDC2ckQ4AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAxLTE5VDE5OjQxOjM3KzAwOjAwxy/8hAAAAABJRU5ErkJggg==";
      break;
    case 33:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAEdSURBVHjalJI/y0EBGMXP5eomy51uMghfQFFkMfgOyoewKiKLZJfRwGKhWO83sFBmg4GF/BulnPMu6tWl988zPf3Or7M8jyEJfx7fB3a/YzD4rOt9djvG4zoc3pO37tsNloXTSZXK791sNLReq9UioOHQk77Z2ayqVUlMpxkKabP5yValQkDzubZbmqY6nU82KdfVdKrLhdEow2Gdz6zVmExqPNb1+mK7LnM5AgwGtdtpsSDAUkmzGQECjMXU60mCRiP6fE8KMJ+XpGaTlsVC4ZsDarfBSOQVEVC9rsOBluXhtG0wk/HSQED9Pm3by1MpaLViPO4NTNNLEgktl4Yk7PfqdjGZ4Hh83uzxgN//3B0HxaJRLsNxjH/94NcAXDVHXhw4crcAAAAASUVORK5CYII=";
      break;
    case 94:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAICSURBVDhPPZM9a1RBFIbfM7N7b7TQLQRTKsRCBMEmpViobRC0if/Cyr9gJ/4KbUSx0sZasBPEzliKhYgkm/sxc3zO7MaEh5k7M+fjfWfWrj++6+tpkGyh1c2ncutUZTpanVe1rORVV/4cM7qsFv388Ir5rJykNNdZwdSwxuzS6AlMA0xVW9grFbyRSnZVsngisw8N+chYqVCUGXONvdIqezLIkGTXnj/w9RxBpvL5LweMtrNWt5DgfQv69f5lG4WM3XsPm5ylCsmpeIb5BCOLExXRhM4GkhJVI4HjjRMc1WmChEagVZVUWRQyXJ0V9TZDtLo57GmhQkA1ugQ7ePPIT+tAi6ZvP8ZoRsuS9OXJa3XMJyrffvYR8zJB0veLF1pwFyeRzxEqQ2ySl7aqlnTTYcqSg+EB9m3G+AaENklU3cB5JmSAEZ2ndWoozEKzuN923xD3b3svDvykDHxI86ffGBOxrn5/F+1xPVknb1dtrOi+fP+wjc3tcMjA0ZSpiP2NkcCZ9oNo63+1kBjf0XbsWTyCOBNXFo8mVy1YiM3woy5mwHXWVWYYgWvdO7zT3naYlPcvtQSiwo2rPBDar5z/eoReanGXWr9b8eK4Y6xOYdIG3GQS/3Eu/tqTDPeREMmjvfDkTELqF52CbtnpXOq0k3tg5NfV24761LO+XQu6vCXrHxw4SAabBQekAAAAAElFTkSuQmCC";
      break;
    case 104:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHNSURBVHjalJJNSFRRFMf/5977Zt4b0qwMJiYXGZg0VMwyxShoYzYRLURaKK6EaBMRSotWQ1EEEgR9LMSFRJvCCpLACCKYiHCEDCMjJ5xFQ6TVNPPmfZ4W7/kmd3V358fv3vs/515iZvzzUgBGnyzczReVoAAdSLU8G+mSgvjVLXNuOlIpliBmXv5e23dttmp761w8GMr0Nn9VU4M2ZGRrB4cpSHL9xdKFxwuR3d7kP990e2e9aEEFSHYc1gduiqA429OeTjav2/7nX/Eba2lFbrh72674iRxAoa0reeV4utEOOXfqXQW3zSAHmhE/dZUSWwCISMimk72dKUCBFSBMf/OoecwF6X2XxI7wIPp7gpVaffb9CigkPosjTeWtnd2NKxv26hf39aQtEwADpJNToN2Zo/2CaMO8AcC1zOmLXCoIMIMMsj95rScrZ4bspVxfR2SHua2nOb80zyAPQsD/zfHB6kDJax1/+WGxXNlgO2/vu/MPw2RgSXyuls07e0BWzfbOP3rXsL3iG3vmclQb5OS3ZyesQyArIDOL5XtzK+Ebje1dg2NSzCBNJ02Pte1Pnh5f/uGUflYNTRqa1DXx8Vu1P5PSlaT/+oN/BgCHsq7GhfpagAAAAABJRU5ErkJggg==";
      break;
    case 107:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAG1SURBVHjanNPBi41RGMfxz/O+995x3YWmZKUkkShbSkpK2aCkWLCRspOFjYWSjSSx5h9ATVhJypaVhUJsZMNOzDQz7sz7nsdi7kz3Ttn4rZ7Oc77n9zvP6URmgjh4GVNoifqQqN/jt0RBhbRSTC3K1/dUJpSor1M/Xt0qw780DvdE576ob47AVjuq/sF3xtgnxMmRYUMuKElVXxSOYavwVXqOR5NwdI6sJkWlOKyOayKPjpntJ88q1XbcGo+9ODoFhjJOiZjBJTzFwko7abrX18UeqV2e0akuKNXs2oSjfSCqPdTPZNm5ajTmHBvk8je/P51naVYPUW2lbLZUGM5/NPxxXJYForMOzlq6YXrXouhOyXwkyxdRfRbVOfUGOtOfiVfkYH3sX6ruG4LMfdKZ0Xpft36AE0Qr7aLMTsJZ5kQ90P6hNEPdgbUBZvNdVA9plkRcUS/cXu/8TDiv23uniQ+yuSrqS/ipra7QvhV/aOuN+v2Xk3ee23hHaXcT++m1SnNXlr04QLw1nGN+vqcdvLA4XSbhxk9Ne1qbO4hNso96eS36YBv9LUtyeeWtEau/6n/0dwDJ3qU3nLoNmQAAAABJRU5ErkJggg==";
      break;
    case 208:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIRSURBVHjapJJPSNMBHMU/vzkRtTLTCms5G4UkmNPCS3joYBGBEgSdUugQUeA5KOsQCEHopcLwltQpg2VomBWaWS5NS10pstw0kDkDf/6Zf7a9DrpRoBd7l+/3e3jv+3g8QxJbhXV9uoCU1dUIn/u9jE9MM/bdi5GUjPOog5zsTPKPHPibtwiUG+ufFfw9x/uPI2Tt3Ulhvp2kpEQApgKzDHr8gMHJkjysCZaYgIEkFhaXzRevvmgqMKvN0DfgVXvHkMLhiCSZkkASn3rHzEHPhCSpyz2qMV9gQwH/RDC2mpKwAFgTLOQeygLg7oMWLlfV09nlAaCq+gm3ap4CkJaWQmtLz7+B+X4FOeY8CMD+rHT6vJPU3W9mxBcgHI3yoWOIxsN2XO0DJC+ZPH87TMO9S2u2m5rdZsxPybkadfZ5JUlFp2/rzMVaSVJdQ5uu3WiUJLGv0pS09tluyyQcjmC1JlB2qpDrNx9jz0zlQlkx4UiU8vN3uHK1nG8eP7UP2yASXfMtiW73qOn5MRkP5pmrR/WPWuJ3U1OnJKn1zVc58ipUUFptSsKQxPzCsvmua3j7ieJcdqWnbtgmry/Ay9cDROaDVFSWzWWkb9sRL8lUYJZu9yjZtgyOOx1xkjkXonfgJ6GlFc6WOv/WM2JkF5ASCq3g7vcy7p/GnJkhYiRis+3GYd9DUUHOpvXcEiz8B/4MAOAmYO/+IRCKAAAAAElFTkSuQmCC";
      break;
    case 237:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHNSURBVHjafFJLaxNRGD33m8wkGVuTjKCY2ARbi0YRdFWCi+ImmK3gzo0bQfGBxW7cuxBcuHMhiFoQXdgQH/gDklaR4qbEB02oEigmlTwmzWSe97pITFNTPLvzve79zvnYymQSIyBV5YYxGvcNEyUWjc3PjadmhON4rZb54+fmwvP2x0+7VI+dSU09uK/nl9YuXuqWyowoMH0kdHaWybJeWIYQANjKZBIC6qmTycWXnWLx98ILCgaE69q/quZa2apUdvzEp2njqZlDd+Zb+aXGYk4Kh3xaxD8xETx21J+Ie51OPZurPn7q1DYBsM8nTguPH3+XXb952/jydXiSfGB/JHPu4NXLpAZLV66388vEu6akBt16w/j2/R8FnGqt9uTZ6my6mS9MP3ro0zQCwGTZ3tgA59gNvGusX7vlNpuRTJoAeHrb65pgbHubcFiJRbc7PG6sFiEEAeCWZZbK/qnDg2x07kby9SsQ9c0K7aWxPfW37/u8ns35E3EQgYgpCiDY36eYokQy6crde56u991xG82twgcAgUR834Xz3DR7dvTQyL7hlrXDyx6HJEmqyi1zEBe2PeijEQ2EGJr6v6sCwLc6wrK5YwshRjX9MwD+2sQD20XRLwAAAABJRU5ErkJggg==";
      break;
    case 246:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAFMSURBVHjaxJO/isJgEMR/+QfRVHbWwqVUtLBQsBEEH8IyhQ8h2PkOCnZBkHThQLDzEewsFRQsgwfid/HbaxLh8BrvihsY2C1mZxl2ERGAGFAvMM50xID8grGRTXJ4HZ8mf4CdF5PJBMMwKJVKKKVwXZckSfA8j9vthmVZiAhJkjAajQB4OA8GAy6XC61Wi/v9TqfTwbZtfN/H9316vR6VSoVyufzNXQGy2+0kh9ZatNY/9qfTKQ9MPdb2PI8gCDAMg+l0SrvdJk1TLMsiDEP2+z3D4RCl1LPzer2WarUqjUZDttutNJtNWa1W0u12ZT6fS71el81mI8fj8dl5sVgQBAFaa2azGaZpslwuATifzziOQ61Ww3Xd57SjKKJQKJCmKVEUUSwW6ff7HA4HwjDker0+Qsvxf0diZfwA3l7QvQNLsu8Yv/hVYxHhawDo5dHHEiGG7gAAAABJRU5ErkJggg==";
      break;
    case 269:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAACdSURBVHja1JIxCoNAEEW/iwEluUMa9Qo5gIJ3svMc2ngD75JUqUOqlNsJPotlE4OdW4QM/OYz7zPMTARobxkF1O9g8dGFOLYkiUXaKk0txliks2c8eEK6U1XQ9yBtNY6Q5yBd1/AR6YEEdQ3zDG37DQ4DABSF925IByG93k1l6ZqmCZrGeV3nAgGybB36DF5Y0NhBC9t9qug/f3sZANtf3lnmeUF9AAAAAElFTkSuQmCC";
      break;
    case 318:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAGxSURBVHjapJK9bhNREEbP3N11bMcbwxIigkUaDEVAChFIiDSUtLTwBrwALRJIvAMSQgIplNTpKRAFTSSIQcgNIimycowd/2TtnaGIbC+LK5huiqN75n6fmBn/Oo7/GB8gjuOfnueFZsbEREQA5u4i0o2iqOYDeJ53UVVx7m+RCTRVdQ5VDafaZtYVEfL358HsyzN4PMbKZ9BqBICWz4IZlqaYF6ClKlZawoIFUP3zZiuVcc1PnCsP0bVN2s090vr6qeZghH/wDYB0tY4WysivwwwcVgi/vOb7qxe0nza5sfuMwdEDMMVvfMSKAbp8mYX32yRb9xnVrs1gGQ4J7j7kzZM99h9v8/LRCYs7z1F8tFAFX6HVxP1okK7VSa7cysCdDv3zW9x7+w530KB/4SpaGzFevQ4CWErh8w6lrx8gk4jPaVb04pg7GyGycZPD5DYSgDsxDEGLwOAYEp/xpU3kuAeVRcTMaLVaHSDMxTHLR1O0sowVBddLkF67G62sLPnzajcBp9k7D9c/gr4gAup5ZEsS5gsxryBgqCoiEs4+TGRfVcNsf/Pqk11EUNUuwO8BAJ+ZwbcqmuG7AAAAAElFTkSuQmCC";
      break;
    case 341:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHaSURBVHjapJPPS1RRFIC/+94d3/grh5hgmiFnmGYSMSgapcgSQxcVLqKitkIgtHHnsr/AZYuSImhTLYzatyjUQCpnVBybBMOopsLJMSd99u68d1s8KYIgzG93OD84nI8jyIxEgTtABjD4N1VgHBiUwF2gl+3RBzQZQAfbZx3oMACX/8M12AE7bBbAhgVKwo8A2BaIv1QKoGr6+a1Yokz6Lz1jbjHKrvpNIuE17j06AUKDa4LpQo2C73Wk2pbozrzh9mg3oJE4JgPnx3jyopX21ne0JT8xmU2TShZpSXwmV2hmaj5Ob2eeTS0INdocO/iWyWzKX7uyHgQNAemiNYQabOKRFQ6nP/Bw+CYXenLcH77BzEKMSPgb164+BrF1MGl6FEshsq+bmSrEyeYTnD6eJyA9HCVJREts2BaqKkGDo+Sva4vaoEOtpVhebaTryAL9l59ytnOO5zP7EQK+rtXjKMn1oQf0nZxFawNACA7dKp/pmg29/7KbpY9hrpybYCyXJhkrsS+yQnG5ifFcmtieVXqOFphf3Eu5UleZeHXAFbSPjGLXnML0wPR8bZYCZYIWviLL8TUp6dcYXpmgmpZoBgk6v7+qwfY9Bqp/ejY9f6j/VdP65cDFnwMAjTumK7WQej8AAAAASUVORK5CYII=";
      break;
    case 376:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAE7SURBVHjaYlTnY2IgGpCglMaqmUXYGTm5uETFJQIi4zwDQz+8f/vl8ycubm57d6/4rPy///4amlu5+gacOnwQavb3b9+ePX4kJCxq7+59/fJFJiam71+/fHz/Ljg26ebVS1vWrODjF1y28zDCJYyMjL9///r+7SsjIyOE/+/fv69fPjMyMv759XP3lg2aevqE3f3nz59//xns3TwvnT3FwMDAgkvd////WVnZXH0CWFhYv3/7Gu/jgqL6////TExM///9+/PnD9xth/fsePv69bevX1hZ2f78/s3EwMAQmZrJwMAgICT86sUzNjZ2CSmZv3/+/v37l52D4+ePH3///hUWFZNVUIS628030NbVV05JeUJznXdIZOfMBWycnEbm1k8fPrBxdv/397+5rePERasZGBgYB006AQwA3CiBUnG8Lx8AAAAASUVORK5CYII=";
      break;
    case 422:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAFlUExURQAAAAAAPQAAPAAAQAAAOwAAPgAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPQAAPAAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPwAAPAAAPQAAPQAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPQAAPQAAPAAAOwAAPAAAPQAAPQAAPAAAPQAAPQAAPAAAPAAAPAAAPP///1aGTxAAAAB2dFJOUwAAAAAAAAIXOmJ5aUIfBRBLWFNFZktTWiI8WzghFl4xRkEVY0gxE0mnawwpSj4pnsSmRCxPA18/JiCYGjdkEgIuIheCyrMsZQ4qQy6yoYZZUUMbnnuZaQY5xq88bwczVyUtcK0URExhOS0kGT4kJmcqDFJNgAFu2p6UAAAAAWJLR0R2MWPJQQAAAAd0SU1FB+cBExM0NtKpKxwAAADrSURBVAjXY2BgYGRj5+Dk4ubh5WNkAAImfgFBIWERUTFxcQkmBgZmJkkpaRlZOXkJBUVORhYGJiVlFVU1dQ1NLW11ZW0mBiYZZR1dPX0DQyNjQRNFIN/UzNzC0sqKS9HaxtaOiYHRXtzB0cnZxVXazdrdAyjv6e7lLSHu4+tnw+OvADQvINAoKMAqOCTU0ogjLJyBJUDFMYLDKjLKKNrRPCaWgSkuPiExLilZOYVDIjUNqD82nUM4Q8goM0s00zabBehedluTnFzFPEme/AJGoIMZWAuN8sWlikSK2JlAXKAHWVOKS9JlSkE8AOdmJe4/4hW/AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAxLTE5VDE5OjUyOjU0KzAwOjAwa0wz4QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMS0xOVQxOTo1Mjo1NCswMDowMBoRi10AAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDEtMTlUMTk6NTI6NTQrMDA6MDBNBKqCAAAAAElFTkSuQmCC";
      break;
    case 456:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAG8SURBVHjatJK/axNxGMaf9xJtrqHaIDjdgaY3CGooMWgrtBQKTi6KLoKLOIjiL6xYaHETCg6CWyEERAqV2oZ2dFCpNqJY/wEb29z3uCTf5GJaLynJ9e51CBbc7OBned4HPssDLzEz/hkFe2FvdrgTTWFtpjOhN2+DWC9fvnBgeMjNfeK5BUVWdkZHDl6/Fj16BACYufx11U4OVjXDvDfWLJb4D9uyIsYnq7pRSqTslRwzo1n7aQ4OO5qRv3Fr1/Nbrd177cEjRzOs5IArpeIsLnULu90VPnT3NgCvuW2NT8pz54v3H7a2fgE4fOdmW41EZNWZm1fwbjkg8jWtJx4HYM++UmdmsV7Y/zprpzMAenR9x4gHROH3H5R9tRoTEFEpHALABRMAEwKi0I91ACAiVWUC6ptKK9mvMJNdbNfrAKKXLrZjsRBzEO3uunoFgOe6VBDEHJw8jurqN6kbUjfEy5nOrK2NjfpC1v2+1qlmdrEjlFdyYOb8kylH6xPHEuXlj/w3zucv4sSpmtaXn3jMzGDmwPetZ89l/2mZOlucTjcKptdoNIQoZV5UzgxVEilr6qnvecxM//Grfg8AnDoOE9lvhZ8AAAAASUVORK5CYII=";
      break;
    case 745:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAE+UExURf////r9/fvl5fnr6/z+/v/q6uWzs9pLS9VkZO+8vOzi4vn7+/d7e/ouLsBzcdRjYv89PbdTU+uqqv/29vz8/PHx7fb7+PuyseN4d+319Lm50Le6x/9/fPE+Pfzi4v7+/ePj55SUxmxsoZeWuby/0aisxsrK3z4+nyQkd7i4xba63aastvLz8+/v9Wtro1BQnGdntJGRw4aGtk9Pl5ycvCcnlSsrjrS0yTk5lGRkfu7u7OLi8Csrkuvr6ZubzklJlu/v5mtrt3NzpPf38EBAoWVlgO7u7SQkicjIxvj49+Xl3oSEt0pKl2trtnNzo2Njfvb2+pKSxTExhU9PpWlpoHR0pEpKlerq5La20ExMl6qqwzg4lISEn/T08/Pz+a6u2Kmp1MHB3dbW5cjI3/r69sPD3dbW5MDA3eHh6f39/Ri7y34AAAABYktHRACIBR1IAAAAB3RJTUUH5wETEzIJMpWhpwAAAGJJREFUCB2lwTEOgkAUQMH3kg8kQrbCysKb2HMJz+gdTDyKjaGTSgvcXQti6ww/pPLjDUjRWCxI1nRWc8Bo9Wp1jqNbRHKLSHrl5BdB76Q+Bxd2D2Jv0eug6RaXs9n9YMa/Vpj7DPFRH8mdAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAxLTE5VDE5OjUwOjA5KzAwOjAwRo6MeAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMS0xOVQxOTo1MDowOSswMDowMDfTNMQAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDEtMTlUMTk6NTA6MDkrMDA6MDBgxhUbAAAAAElFTkSuQmCC";
      break;
    case 655:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHySURBVHjaxJNPSFMBHMc/7/m2x2zPubctbZqtppQFeUqiCIIwI4kOQSQV3SKCILrV3VNEdI26eYkCC8tLQRQhBoFpLSc2B5X9m9P23mbbm+/9urRBHfPQ5/S7fPj9+P5+P0VE+FdU1sCaZA1YAAyA2/feMfokQ4OmUrAqhEM6x/q7OH64C1VV/nZtDYjXx1AgPb/MhdM9bOs0efQ0y8WhZxhBHwP7N/9hlj0xVMB2qi6vUzk+fS0RXOenfUOQ7Z0mUTNAe0uQmNlYl0YWChx4nmEiX7IVEbG+5UpG39kHhAMq3ckIHz6XKBQddN1PSyTA0KXdbNkYouC4HBnPUvVgdG/CVgHMcIDLV/YQ26QxNZnl/KmdjN06ysmBDiZTX7h5JwXAy8JPZosVziTCRHUNDcCnqQzuSpB/b3Nt/BVv55YQaeDNzEd0tcRcxiK/uMK069Hi1+hbHwRAERGrlvbw/TQjjzNomoJVXKU16qPV9JGasujuTzDTGyfpeFzviQPYiIglIuJ5Ik7VlVrtup6IiFRXRU6ceyi9g8Oy9e6EjFkV+Y2l1bqCMJ3Okf9RodnwU664dLQZJNqaOHQwydUbL9ixuMI+w18L3lBEpH4k3/MrLBXKNAZ8OI5Lc5NONBwAYHZ+mVhIx4zU12Yr/+0xfg0ASgXbcRnB1DEAAAAASUVORK5CYII=";
      break;
    case 756:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIpSURBVHjapFNNS5RRFH7Ove/nvM6MOqY5oNWiKClCTQk3bWIWLuu3RNknRQS1NdpFi2hXEW1qE1IQCOmiRWgQIaGlozQzNl/vfe+9p8WYw+Suzu5wvp5znucQM+NfTeA/zLn7ovDQiTDBFhoAsWVIKHpbGcdi9SgCofA3NiKSRsXvnFyufnCznDohBQPUCjI5GE9/xheVR9WkIGE7ihkMEQYroqHFBelIBWr3NyzQI2s4ktl6b8jdi5c55sRcl9357h++Jwa6ut1J3hlAgsBN/WFhXk1vuANng9DNY/ewDO37D2I3fCTGhgcw6EW32fD6n8aaYWK1dXmqf73U55iZhnWsYQHNArC8zuTcoVQAWbh5Gro/qGlJsWgm05aBtMdvjg+F98+MBNmJvs3VpUbuJFs+HLkGP6vhpaTCcygp0K0nkwAIwlrP64rm4bujk/vNdtajRDGTT9o0jHTq1u9xhF4sg6fqiauIGM52/LWF1RqF6MDMWHbfq4zHGWVbO8Ys4QjGYFeCl0/Xrsy9XlNRJMAA6PHa0O4hJICR7eFnSoXnmDrp8X3zvFisny8WDaRocUo3av1tioRFUM0cK3w/tMBkU+1SWetNF09l0rVlISSwIxsn/6udY8HIWiz5XmO2mQQXAYYgoNlIZldLqWVQ2Km0T4uFtgOACaiz7dFSf5SShpShlW8bxVGdJGUQdWrbhHaPgDzYkmS+ZljcA1eu9vbpshB7f+j3AF6n/I/wu3zeAAAAAElFTkSuQmCC";
      break;
    case "0":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUNJREFUOE9tU8FRBDEMk560dZRACTygi2y6gB5o4WiLp28s2Ulg2MfujmPLkuwQAAgCCIQ/eir2lDEGfyIP8jyfynFevaJACuONxMeRl0XvAD/zhwQiDxO8MLvtHYGbQQ46q4nafYN4NgtnrT+G2e3SFCLy1aBOWiuTDLMsdTrxLIaPSrPBMuOAlFeHe13+v4gGOA0vG+ICMGw/aYfcrGRlVExKZeZNBK728SJjuC5lmSrF1QNsAImlzJshACHzImJUSzJW6/ZPeYrLer0nkHXmegExjFWTkdOLcUutmDILwFuRSKOVHxvQW9OwrSjpTEASDABi1MB3t57QmmBZYjkzBOBJLgC1lEbx2KRsXEbal0kzsNLSX6u4Z+aFX8Pr61NL1Yu3cs6r8esq/VnlvbHpwGsAL/tWnn99F3ozvca2TbGvB0GdsXmb9kprAAAAAElFTkSuQmCC";
      break;
    case "1":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAJwSURBVHjaTJJbSFNxHMe//7MzN22RUZqTIBBGGgWWDwPdgxcCKRCZomVZUstrD85LSl4oTKGkQlmSYMFUmikrFqUZahJp5SVQiIjUrKzMW5vXs+2c8+vFsC+fh+/Dl+/Th5E4CLEfDJshgAEKERK/2QlgBAAKPQ+pG8LVrTUHKDH3SxG4S4IMSAAPAO4NqDTZHKACwyZqSExR3XEmtMhR2pqz7lXDD9Pz2nRL+cuP++HD+K1LNcY+60pbs77MBWXEdDf1Hev7EG4x1TveGYYmwiqNC5AVIHcNrWDNqb7Vnqo9/WhHSmf3gJ4IQ+MHYkrq/FOeld+/8PPHbtoACQU8GHmIMzWU2Abi/DXLHJOHp0IF0aeyI6P+7B3HSNS1tnPjX0Ns+eV+fhIPYkomlyU3A5ieDzLFdr6dCK3rMuYcffL600HHSKTR0FuRbPXlJRAHcteQE+QFiWjpig/LajYUWuy9sUlVVcHp9tsPT5AbJIGcIMHMA4ACM7OBtY6Tj4cNSfpXIQGz5pbs8H2TN9KabIPRo9O6iiSrbs83BvBg5Ja5Qmtee3985nF7uqEnzVJ2OdHmFflLD0xrbrVrbfucc6e9uETjI/MgplLKdzNv6oJnLM8TJ39r72XXRh0eG3x/SJQVDFSc0FaUYNP4eEEcI2/V0mL1+cYKrf+f1Mg+szXv+2JAbryjZyzCLSqvn2qMOzICDyACKjNIvvJmdK8+v7F3KIJErCxtMzdc9DW+yK0vcC5oyANaBi2DXCDBDPJUrsxCWAQJIBdoFbSOqQkNrYNWQa5/OEFCDiNvD6SnYASZ2zKOFyHyIPafmwQ++u8AoNItxauVAEEAAAAASUVORK5CYII=";
      break;
    case "25":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACTFBMVEURIUIMJDsVH0EJJDgSI0ITHEMPH0ERIT8SH0QRIEEQIEESITsXHjsKIUsOHT317s8xND8SHlERHkwQIUASHkwWIUENIVUjJyqrjRTBun0UIysOIUASIUINI0ISJiwRJjnQzpLDlQShkTg8TkIHHEIRIEMPJjcOHkUlMGhETlAkHgseHBPW2+AUKUkRH0ARIkIRHTcWIja9ydsZLGsNFk1Xa7MPHkE5T3ASIUMSIkMRIUMVH1UTJmUSLEgSLEdPgK0QLU8WK1CWp8oPHT4UJEUQIEAKHjLe5e8WKVURIEJZbMcRIVQdKk+Em8NBW5ITIkMSIEMQH0APHz83UYQLG0FKZp8LHkcOH0JMaJ8SI0YLIEpne6sLHD8UIkQUI0QXJ0fa4O0pQnUmR4irvN4uRncuTYm9yuYuRHowR3xRaJ4OHT8yTHs+YakXJkYhOGw7W50PIkhEY6obMmU9XZ4OIEUUIkUYJ0hDXpMMHT4kPW41T4USJUcrRXYwTYQPIEEnQnhLY5oJFztGX5oUI0MMHD8zT48/W5ZBWo/K1etJZp1AWpGgs91VdbRQaZu6x+ZJY6RDWJSGoNIPJEgVH0ARHj8YN3k9WpoQIUMvSn4uSoQJGzg2Uo5AW5EfMFU+VogxSIYRHjwySoBMaaEQIDo1TYYXLFEpRoJCZawMGzoWLFUyVJYOIUIfN2Y2WJwPHjsdL1o5U6IRI0MXLlYXJ0xOZZY9UoY5T4NEXZEyRngvSXpFXo45UoczSn1DWYc4UIRPY5kzS3w8V4cbL1v///9469hQAAAAAWJLR0TDimiOQgAAAAd0SU1FB+cBExMpJjByV2QAAAABb3JOVAHPoneaAAAA/klEQVQY02NgYGBgZGJmYWVj5+DkYoAAbh5ePn4BQSFhmICIqJi4hKSUNJTLICMrJ6+gqKSsoiojAxFRU9fQ1NLW0dWTgYjoGxgaGZuYmplbcIIFLK2sbWzt7B0cnZzBKixcXN3cPTy9vH18/fxl/BkYAgKDgkNCw8IjIqOiY2IDgQJx8QmJSckpqWnpGZlZQBWxFtk5uXn5WQWF/kXFJTKWDKVl5RWVVdU1tXX1DY1NzRYMLa1t7R2dXd09vX39EyZOmswwZeq06TNmzpo9Z+68+QsWLlrMsGTpsuUrVq5avWbtuvUbNm7azLBl67btO3bu2r1n775t+w8cPAQAVCxPM7SFbR4AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDEtMTlUMTk6NDE6MzcrMDA6MDC2ckQ4AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTAxLTE5VDE5OjQxOjM3KzAwOjAwxy/8hAAAAABJRU5ErkJggg==";
      break;
    case "33":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAEdSURBVHjalJI/y0EBGMXP5eomy51uMghfQFFkMfgOyoewKiKLZJfRwGKhWO83sFBmg4GF/BulnPMu6tWl988zPf3Or7M8jyEJfx7fB3a/YzD4rOt9djvG4zoc3pO37tsNloXTSZXK791sNLReq9UioOHQk77Z2ayqVUlMpxkKabP5yValQkDzubZbmqY6nU82KdfVdKrLhdEow2Gdz6zVmExqPNb1+mK7LnM5AgwGtdtpsSDAUkmzGQECjMXU60mCRiP6fE8KMJ+XpGaTlsVC4ZsDarfBSOQVEVC9rsOBluXhtG0wk/HSQED9Pm3by1MpaLViPO4NTNNLEgktl4Yk7PfqdjGZ4Hh83uzxgN//3B0HxaJRLsNxjH/94NcAXDVHXhw4crcAAAAASUVORK5CYII=";
      break;
    case "94":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAICSURBVDhPPZM9a1RBFIbfM7N7b7TQLQRTKsRCBMEmpViobRC0if/Cyr9gJ/4KbUSx0sZasBPEzliKhYgkm/sxc3zO7MaEh5k7M+fjfWfWrj++6+tpkGyh1c2ncutUZTpanVe1rORVV/4cM7qsFv388Ir5rJykNNdZwdSwxuzS6AlMA0xVW9grFbyRSnZVsngisw8N+chYqVCUGXONvdIqezLIkGTXnj/w9RxBpvL5LweMtrNWt5DgfQv69f5lG4WM3XsPm5ylCsmpeIb5BCOLExXRhM4GkhJVI4HjjRMc1WmChEagVZVUWRQyXJ0V9TZDtLo57GmhQkA1ugQ7ePPIT+tAi6ZvP8ZoRsuS9OXJa3XMJyrffvYR8zJB0veLF1pwFyeRzxEqQ2ySl7aqlnTTYcqSg+EB9m3G+AaENklU3cB5JmSAEZ2ndWoozEKzuN923xD3b3svDvykDHxI86ffGBOxrn5/F+1xPVknb1dtrOi+fP+wjc3tcMjA0ZSpiP2NkcCZ9oNo63+1kBjf0XbsWTyCOBNXFo8mVy1YiM3woy5mwHXWVWYYgWvdO7zT3naYlPcvtQSiwo2rPBDar5z/eoReanGXWr9b8eK4Y6xOYdIG3GQS/3Eu/tqTDPeREMmjvfDkTELqF52CbtnpXOq0k3tg5NfV24761LO+XQu6vCXrHxw4SAabBQekAAAAAElFTkSuQmCC";
      break;
    case "104":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHNSURBVHjalJJNSFRRFMf/5977Zt4b0qwMJiYXGZg0VMwyxShoYzYRLURaKK6EaBMRSotWQ1EEEgR9LMSFRJvCCpLACCKYiHCEDCMjJ5xFQ6TVNPPmfZ4W7/kmd3V358fv3vs/515iZvzzUgBGnyzczReVoAAdSLU8G+mSgvjVLXNuOlIpliBmXv5e23dttmp761w8GMr0Nn9VU4M2ZGRrB4cpSHL9xdKFxwuR3d7kP990e2e9aEEFSHYc1gduiqA429OeTjav2/7nX/Eba2lFbrh72674iRxAoa0reeV4utEOOXfqXQW3zSAHmhE/dZUSWwCISMimk72dKUCBFSBMf/OoecwF6X2XxI7wIPp7gpVaffb9CigkPosjTeWtnd2NKxv26hf39aQtEwADpJNToN2Zo/2CaMO8AcC1zOmLXCoIMIMMsj95rScrZ4bspVxfR2SHua2nOb80zyAPQsD/zfHB6kDJax1/+WGxXNlgO2/vu/MPw2RgSXyuls07e0BWzfbOP3rXsL3iG3vmclQb5OS3ZyesQyArIDOL5XtzK+Ebje1dg2NSzCBNJ02Pte1Pnh5f/uGUflYNTRqa1DXx8Vu1P5PSlaT/+oN/BgCHsq7GhfpagAAAAABJRU5ErkJggg==";
      break;
    case "107":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAG1SURBVHjanNPBi41RGMfxz/O+995x3YWmZKUkkShbSkpK2aCkWLCRspOFjYWSjSSx5h9ATVhJypaVhUJsZMNOzDQz7sz7nsdi7kz3Ttn4rZ7Oc77n9zvP6URmgjh4GVNoifqQqN/jt0RBhbRSTC3K1/dUJpSor1M/Xt0qw780DvdE576ob47AVjuq/sF3xtgnxMmRYUMuKElVXxSOYavwVXqOR5NwdI6sJkWlOKyOayKPjpntJ88q1XbcGo+9ODoFhjJOiZjBJTzFwko7abrX18UeqV2e0akuKNXs2oSjfSCqPdTPZNm5ajTmHBvk8je/P51naVYPUW2lbLZUGM5/NPxxXJYForMOzlq6YXrXouhOyXwkyxdRfRbVOfUGOtOfiVfkYH3sX6ruG4LMfdKZ0Xpft36AE0Qr7aLMTsJZ5kQ90P6hNEPdgbUBZvNdVA9plkRcUS/cXu/8TDiv23uniQ+yuSrqS/ipra7QvhV/aOuN+v2Xk3ee23hHaXcT++m1SnNXlr04QLw1nGN+vqcdvLA4XSbhxk9Ne1qbO4hNso96eS36YBv9LUtyeeWtEau/6n/0dwDJ3qU3nLoNmQAAAABJRU5ErkJggg==";
      break;
    case "208":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIRSURBVHjapJJPSNMBHMU/vzkRtTLTCms5G4UkmNPCS3joYBGBEgSdUugQUeA5KOsQCEHopcLwltQpg2VomBWaWS5NS10pstw0kDkDf/6Zf7a9DrpRoBd7l+/3e3jv+3g8QxJbhXV9uoCU1dUIn/u9jE9MM/bdi5GUjPOog5zsTPKPHPibtwiUG+ufFfw9x/uPI2Tt3Ulhvp2kpEQApgKzDHr8gMHJkjysCZaYgIEkFhaXzRevvmgqMKvN0DfgVXvHkMLhiCSZkkASn3rHzEHPhCSpyz2qMV9gQwH/RDC2mpKwAFgTLOQeygLg7oMWLlfV09nlAaCq+gm3ap4CkJaWQmtLz7+B+X4FOeY8CMD+rHT6vJPU3W9mxBcgHI3yoWOIxsN2XO0DJC+ZPH87TMO9S2u2m5rdZsxPybkadfZ5JUlFp2/rzMVaSVJdQ5uu3WiUJLGv0pS09tluyyQcjmC1JlB2qpDrNx9jz0zlQlkx4UiU8vN3uHK1nG8eP7UP2yASXfMtiW73qOn5MRkP5pmrR/WPWuJ3U1OnJKn1zVc58ipUUFptSsKQxPzCsvmua3j7ieJcdqWnbtgmry/Ay9cDROaDVFSWzWWkb9sRL8lUYJZu9yjZtgyOOx1xkjkXonfgJ6GlFc6WOv/WM2JkF5ASCq3g7vcy7p/GnJkhYiRis+3GYd9DUUHOpvXcEiz8B/4MAOAmYO/+IRCKAAAAAElFTkSuQmCC";
      break;
    case "237":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHNSURBVHjafFJLaxNRGD33m8wkGVuTjKCY2ARbi0YRdFWCi+ImmK3gzo0bQfGBxW7cuxBcuHMhiFoQXdgQH/gDklaR4qbEB02oEigmlTwmzWSe97pITFNTPLvzve79zvnYymQSIyBV5YYxGvcNEyUWjc3PjadmhON4rZb54+fmwvP2x0+7VI+dSU09uK/nl9YuXuqWyowoMH0kdHaWybJeWIYQANjKZBIC6qmTycWXnWLx98ILCgaE69q/quZa2apUdvzEp2njqZlDd+Zb+aXGYk4Kh3xaxD8xETx21J+Ie51OPZurPn7q1DYBsM8nTguPH3+XXb952/jydXiSfGB/JHPu4NXLpAZLV66388vEu6akBt16w/j2/R8FnGqt9uTZ6my6mS9MP3ro0zQCwGTZ3tgA59gNvGusX7vlNpuRTJoAeHrb65pgbHubcFiJRbc7PG6sFiEEAeCWZZbK/qnDg2x07kby9SsQ9c0K7aWxPfW37/u8ns35E3EQgYgpCiDY36eYokQy6crde56u991xG82twgcAgUR834Xz3DR7dvTQyL7hlrXDyx6HJEmqyi1zEBe2PeijEQ2EGJr6v6sCwLc6wrK5YwshRjX9MwD+2sQD20XRLwAAAABJRU5ErkJggg==";
      break;
    case "246":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAFMSURBVHjaxJO/isJgEMR/+QfRVHbWwqVUtLBQsBEEH8IyhQ8h2PkOCnZBkHThQLDzEewsFRQsgwfid/HbaxLh8BrvihsY2C1mZxl2ERGAGFAvMM50xID8grGRTXJ4HZ8mf4CdF5PJBMMwKJVKKKVwXZckSfA8j9vthmVZiAhJkjAajQB4OA8GAy6XC61Wi/v9TqfTwbZtfN/H9316vR6VSoVyufzNXQGy2+0kh9ZatNY/9qfTKQ9MPdb2PI8gCDAMg+l0SrvdJk1TLMsiDEP2+z3D4RCl1LPzer2WarUqjUZDttutNJtNWa1W0u12ZT6fS71el81mI8fj8dl5sVgQBAFaa2azGaZpslwuATifzziOQ61Ww3Xd57SjKKJQKJCmKVEUUSwW6ff7HA4HwjDker0+Qsvxf0diZfwA3l7QvQNLsu8Yv/hVYxHhawDo5dHHEiGG7gAAAABJRU5ErkJggg==";
      break;
    case "269":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAACdSURBVHja1JIxCoNAEEW/iwEluUMa9Qo5gIJ3svMc2ngD75JUqUOqlNsJPotlE4OdW4QM/OYz7zPMTARobxkF1O9g8dGFOLYkiUXaKk0txliks2c8eEK6U1XQ9yBtNY6Q5yBd1/AR6YEEdQ3zDG37DQ4DABSF925IByG93k1l6ZqmCZrGeV3nAgGybB36DF5Y0NhBC9t9qug/f3sZANtf3lnmeUF9AAAAAElFTkSuQmCC";
      break;
    case "318":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAGxSURBVHjapJK9bhNREEbP3N11bMcbwxIigkUaDEVAChFIiDSUtLTwBrwALRJIvAMSQgIplNTpKRAFTSSIQcgNIimycowd/2TtnaGIbC+LK5huiqN75n6fmBn/Oo7/GB8gjuOfnueFZsbEREQA5u4i0o2iqOYDeJ53UVVx7m+RCTRVdQ5VDafaZtYVEfL358HsyzN4PMbKZ9BqBICWz4IZlqaYF6ClKlZawoIFUP3zZiuVcc1PnCsP0bVN2s090vr6qeZghH/wDYB0tY4WysivwwwcVgi/vOb7qxe0nza5sfuMwdEDMMVvfMSKAbp8mYX32yRb9xnVrs1gGQ4J7j7kzZM99h9v8/LRCYs7z1F8tFAFX6HVxP1okK7VSa7cysCdDv3zW9x7+w530KB/4SpaGzFevQ4CWErh8w6lrx8gk4jPaVb04pg7GyGycZPD5DYSgDsxDEGLwOAYEp/xpU3kuAeVRcTMaLVaHSDMxTHLR1O0sowVBddLkF67G62sLPnzajcBp9k7D9c/gr4gAup5ZEsS5gsxryBgqCoiEs4+TGRfVcNsf/Pqk11EUNUuwO8BAJ+ZwbcqmuG7AAAAAElFTkSuQmCC";
      break;
    case "341":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHaSURBVHjapJPPS1RRFIC/+94d3/grh5hgmiFnmGYSMSgapcgSQxcVLqKitkIgtHHnsr/AZYuSImhTLYzatyjUQCpnVBybBMOopsLJMSd99u68d1s8KYIgzG93OD84nI8jyIxEgTtABjD4N1VgHBiUwF2gl+3RBzQZQAfbZx3oMACX/8M12AE7bBbAhgVKwo8A2BaIv1QKoGr6+a1Yokz6Lz1jbjHKrvpNIuE17j06AUKDa4LpQo2C73Wk2pbozrzh9mg3oJE4JgPnx3jyopX21ne0JT8xmU2TShZpSXwmV2hmaj5Ob2eeTS0INdocO/iWyWzKX7uyHgQNAemiNYQabOKRFQ6nP/Bw+CYXenLcH77BzEKMSPgb164+BrF1MGl6FEshsq+bmSrEyeYTnD6eJyA9HCVJREts2BaqKkGDo+Sva4vaoEOtpVhebaTryAL9l59ytnOO5zP7EQK+rtXjKMn1oQf0nZxFawNACA7dKp/pmg29/7KbpY9hrpybYCyXJhkrsS+yQnG5ifFcmtieVXqOFphf3Eu5UleZeHXAFbSPjGLXnML0wPR8bZYCZYIWviLL8TUp6dcYXpmgmpZoBgk6v7+qwfY9Bqp/ejY9f6j/VdP65cDFnwMAjTumK7WQej8AAAAASUVORK5CYII=";
      break;
    case "376":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAE7SURBVHjaYlTnY2IgGpCglMaqmUXYGTm5uETFJQIi4zwDQz+8f/vl8ycubm57d6/4rPy///4amlu5+gacOnwQavb3b9+ePX4kJCxq7+59/fJFJiam71+/fHz/Ljg26ebVS1vWrODjF1y28zDCJYyMjL9///r+7SsjIyOE/+/fv69fPjMyMv759XP3lg2aevqE3f3nz59//xns3TwvnT3FwMDAgkvd////WVnZXH0CWFhYv3/7Gu/jgqL6////TExM///9+/PnD9xth/fsePv69bevX1hZ2f78/s3EwMAQmZrJwMAgICT86sUzNjZ2CSmZv3/+/v37l52D4+ePH3///hUWFZNVUIS628030NbVV05JeUJznXdIZOfMBWycnEbm1k8fPrBxdv/397+5rePERasZGBgYB006AQwA3CiBUnG8Lx8AAAAASUVORK5CYII=";
      break;
    case "422":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAFlUExURQAAAAAAPQAAPAAAQAAAOwAAPgAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPQAAPAAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPwAAPAAAPQAAPQAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPQAAPQAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPAAAPAAAPQAAPAAAPAAAPQAAPQAAPAAAOwAAPAAAPQAAPQAAPAAAPQAAPQAAPAAAPAAAPAAAPP///1aGTxAAAAB2dFJOUwAAAAAAAAIXOmJ5aUIfBRBLWFNFZktTWiI8WzghFl4xRkEVY0gxE0mnawwpSj4pnsSmRCxPA18/JiCYGjdkEgIuIheCyrMsZQ4qQy6yoYZZUUMbnnuZaQY5xq88bwczVyUtcK0URExhOS0kGT4kJmcqDFJNgAFu2p6UAAAAAWJLR0R2MWPJQQAAAAd0SU1FB+cBExM0NtKpKxwAAADrSURBVAjXY2BgYGRj5+Dk4ubh5WNkAAImfgFBIWERUTFxcQkmBgZmJkkpaRlZOXkJBUVORhYGJiVlFVU1dQ1NLW11ZW0mBiYZZR1dPX0DQyNjQRNFIN/UzNzC0sqKS9HaxtaOiYHRXtzB0cnZxVXazdrdAyjv6e7lLSHu4+tnw+OvADQvINAoKMAqOCTU0ogjLJyBJUDFMYLDKjLKKNrRPCaWgSkuPiExLilZOYVDIjUNqD82nUM4Q8goM0s00zabBehedluTnFzFPEme/AJGoIMZWAuN8sWlikSK2JlAXKAHWVOKS9JlSkE8AOdmJe4/4hW/AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAxLTE5VDE5OjUyOjU0KzAwOjAwa0wz4QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMS0xOVQxOTo1Mjo1NCswMDowMBoRi10AAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDEtMTlUMTk6NTI6NTQrMDA6MDBNBKqCAAAAAElFTkSuQmCC";
      break;
    case "456":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAIAAAC0tAIdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAG8SURBVHjatJK/axNxGMaf9xJtrqHaIDjdgaY3CGooMWgrtBQKTi6KLoKLOIjiL6xYaHETCg6CWyEERAqV2oZ2dFCpNqJY/wEb29z3uCTf5GJaLynJ9e51CBbc7OBned4HPssDLzEz/hkFe2FvdrgTTWFtpjOhN2+DWC9fvnBgeMjNfeK5BUVWdkZHDl6/Fj16BACYufx11U4OVjXDvDfWLJb4D9uyIsYnq7pRSqTslRwzo1n7aQ4OO5qRv3Fr1/Nbrd177cEjRzOs5IArpeIsLnULu90VPnT3NgCvuW2NT8pz54v3H7a2fgE4fOdmW41EZNWZm1fwbjkg8jWtJx4HYM++UmdmsV7Y/zprpzMAenR9x4gHROH3H5R9tRoTEFEpHALABRMAEwKi0I91ACAiVWUC6ptKK9mvMJNdbNfrAKKXLrZjsRBzEO3uunoFgOe6VBDEHJw8jurqN6kbUjfEy5nOrK2NjfpC1v2+1qlmdrEjlFdyYOb8kylH6xPHEuXlj/w3zucv4sSpmtaXn3jMzGDmwPetZ89l/2mZOlucTjcKptdoNIQoZV5UzgxVEilr6qnvecxM//Grfg8AnDoOE9lvhZ8AAAAASUVORK5CYII=";
      break;
    case "655":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAHySURBVHjaxJNPSFMBHMc/7/m2x2zPubctbZqtppQFeUqiCIIwI4kOQSQV3SKCILrV3VNEdI26eYkCC8tLQRQhBoFpLSc2B5X9m9P23mbbm+/9urRBHfPQ5/S7fPj9+P5+P0VE+FdU1sCaZA1YAAyA2/feMfokQ4OmUrAqhEM6x/q7OH64C1VV/nZtDYjXx1AgPb/MhdM9bOs0efQ0y8WhZxhBHwP7N/9hlj0xVMB2qi6vUzk+fS0RXOenfUOQ7Z0mUTNAe0uQmNlYl0YWChx4nmEiX7IVEbG+5UpG39kHhAMq3ckIHz6XKBQddN1PSyTA0KXdbNkYouC4HBnPUvVgdG/CVgHMcIDLV/YQ26QxNZnl/KmdjN06ysmBDiZTX7h5JwXAy8JPZosVziTCRHUNDcCnqQzuSpB/b3Nt/BVv55YQaeDNzEd0tcRcxiK/uMK069Hi1+hbHwRAERGrlvbw/TQjjzNomoJVXKU16qPV9JGasujuTzDTGyfpeFzviQPYiIglIuJ5Ik7VlVrtup6IiFRXRU6ceyi9g8Oy9e6EjFkV+Y2l1bqCMJ3Okf9RodnwU664dLQZJNqaOHQwydUbL9ixuMI+w18L3lBEpH4k3/MrLBXKNAZ8OI5Lc5NONBwAYHZ+mVhIx4zU12Yr/+0xfg0ASgXbcRnB1DEAAAAASUVORK5CYII=";
      break;
    case "745":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAE+UExURf////r9/fvl5fnr6/z+/v/q6uWzs9pLS9VkZO+8vOzi4vn7+/d7e/ouLsBzcdRjYv89PbdTU+uqqv/29vz8/PHx7fb7+PuyseN4d+319Lm50Le6x/9/fPE+Pfzi4v7+/ePj55SUxmxsoZeWuby/0aisxsrK3z4+nyQkd7i4xba63aastvLz8+/v9Wtro1BQnGdntJGRw4aGtk9Pl5ycvCcnlSsrjrS0yTk5lGRkfu7u7OLi8Csrkuvr6ZubzklJlu/v5mtrt3NzpPf38EBAoWVlgO7u7SQkicjIxvj49+Xl3oSEt0pKl2trtnNzo2Njfvb2+pKSxTExhU9PpWlpoHR0pEpKlerq5La20ExMl6qqwzg4lISEn/T08/Pz+a6u2Kmp1MHB3dbW5cjI3/r69sPD3dbW5MDA3eHh6f39/Ri7y34AAAABYktHRACIBR1IAAAAB3RJTUUH5wETEzIJMpWhpwAAAGJJREFUCB2lwTEOgkAUQMH3kg8kQrbCysKb2HMJz+gdTDyKjaGTSgvcXQti6ww/pPLjDUjRWCxI1nRWc8Bo9Wp1jqNbRHKLSHrl5BdB76Q+Bxd2D2Jv0eug6RaXs9n9YMa/Vpj7DPFRH8mdAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTAxLTE5VDE5OjUwOjA5KzAwOjAwRo6MeAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wMS0xOVQxOTo1MDowOSswMDowMDfTNMQAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDEtMTlUMTk6NTA6MDkrMDA6MDBgxhUbAAAAAElFTkSuQmCC";
      break;
    case "756":
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAIpSURBVHjapFNNS5RRFH7Ove/nvM6MOqY5oNWiKClCTQk3bWIWLuu3RNknRQS1NdpFi2hXEW1qE1IQCOmiRWgQIaGlozQzNl/vfe+9p8WYw+Suzu5wvp5znucQM+NfTeA/zLn7ovDQiTDBFhoAsWVIKHpbGcdi9SgCofA3NiKSRsXvnFyufnCznDohBQPUCjI5GE9/xheVR9WkIGE7ihkMEQYroqHFBelIBWr3NyzQI2s4ktl6b8jdi5c55sRcl9357h++Jwa6ut1J3hlAgsBN/WFhXk1vuANng9DNY/ewDO37D2I3fCTGhgcw6EW32fD6n8aaYWK1dXmqf73U55iZhnWsYQHNArC8zuTcoVQAWbh5Gro/qGlJsWgm05aBtMdvjg+F98+MBNmJvs3VpUbuJFs+HLkGP6vhpaTCcygp0K0nkwAIwlrP64rm4bujk/vNdtajRDGTT9o0jHTq1u9xhF4sg6fqiauIGM52/LWF1RqF6MDMWHbfq4zHGWVbO8Ys4QjGYFeCl0/Xrsy9XlNRJMAA6PHa0O4hJICR7eFnSoXnmDrp8X3zvFisny8WDaRocUo3av1tioRFUM0cK3w/tMBkU+1SWetNF09l0rVlISSwIxsn/6udY8HIWiz5XmO2mQQXAYYgoNlIZldLqWVQ2Km0T4uFtgOACaiz7dFSf5SShpShlW8bxVGdJGUQdWrbhHaPgDzYkmS+ZljcA1eu9vbpshB7f+j3AF6n/I/wu3zeAAAAAElFTkSuQmCC";
      break;
    default:
      bancoIcon =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUNJREFUOE9tU8FRBDEMk560dZRACTygi2y6gB5o4WiLp28s2Ulg2MfujmPLkuwQAAgCCIQ/eir2lDEGfyIP8jyfynFevaJACuONxMeRl0XvAD/zhwQiDxO8MLvtHYGbQQ46q4nafYN4NgtnrT+G2e3SFCLy1aBOWiuTDLMsdTrxLIaPSrPBMuOAlFeHe13+v4gGOA0vG+ICMGw/aYfcrGRlVExKZeZNBK728SJjuC5lmSrF1QNsAImlzJshACHzImJUSzJW6/ZPeYrLer0nkHXmegExjFWTkdOLcUutmDILwFuRSKOVHxvQW9OwrSjpTEASDABi1MB3t57QmmBZYjkzBOBJLgC1lEbx2KRsXEbal0kzsNLSX6u4Z+aFX8Pr61NL1Yu3cs6r8esq/VnlvbHpwGsAL/tWnn99F3ozvca2TbGvB0GdsXmb9kprAAAAAElFTkSuQmCC";
      break;
  }

  return bancoIcon;
};

export const CommandBarStyles = {
  padding: "0px !important",
  root: {
    padding: "0px !important",
  },
}

export const DatePicker_onFormatDate = (date) => {
  return !date ? '' : (date.getDate() < 10 ? `0${date.getDate()}` : (date.getDate())) + '/' + ((date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)) + '/' + (date.getFullYear() % 100);
};

export const DatePicker_onParseDateFromString = (newValue) => {
  const previousValue = new Date();
  const newValueParts = (newValue || '').trim().split('/');
  const day =
    newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
  const month =
    newValueParts.length > 1
      ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
      : previousValue.getMonth();
  let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
  if (year < 100) {
    year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
  }
  return new Date(year, month, day);
}

export const formattedTime = new Intl.NumberFormat('pt-BR', {
  minimumIntegerDigits: 2,
  useGrouping: false
});

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export function timeValueFormatter(value) {
  return formattedTime.format(value);
}

export function moneyFormatter(params) {
  return formatter.format(params.value);
}

export function moneyValueFormatter(value) {
  return formatter.format(value);
}

export function dateTimeFormatter(params) {
  if (params.value === '1900-01-01T00:00:00' || params.value === '0001-01-01T00:00:00') {
    return '-'
  }
  return `${new Date(params.value).toLocaleDateString('pt-BR')} ${new Date(params.value).toLocaleTimeString('pt-BR')}`;
}

export function dateUTCFormatter(params) {
  if (params.value === '1900-01-01T00:00:00' || params.value === '0001-01-01T00:00:00') {
    return '-'
  }
  return `${new Date(params.value).toLocaleDateString('pt-BR')}`;
}

export function dateValueFormatter(value) {
  if (isValidDate(value)) {
    return `${new Date(value).toLocaleDateString('pt-BR')}`;
  }
  return value;
}

export function dateTimeValueFormatter(value) {
  if (!isValidDate(value)) {
    return `${new Date(value).toLocaleDateString('pt-BR')} ${new Date(value).toLocaleTimeString('pt-BR')}`;
  }
  return value;
}
export const datePTbrFormatParams = (params) => {
  if (params) {
    return `${new Date(params.value).toLocaleDateString('pt-BR')}`;
  }
  return `${new Date("1900-01-01T00:00:00.0000000").toLocaleDateString('pt-BR')}`;
}

export function dateFormatter(params) {
  if (isValidDate(params.value)) {
    return `${new Date(params.value).toLocaleDateString('pt-BR')}`;
  }
  return params.value;
}

export function statusIntegracaoItau(params) {
  return params.value === 'Falha ao enviar' ? 'Não registrado' : params.value;
}

export function statusIntegracaoItauValue(value) {
  return value === 'Falha ao enviar' ? 'Não registrado' : value;
}

export function moneyFormatterValue(value) {
  return formatter.format(value);
}

export function currencyColumn(params) {
  return formatter.format(params.value);
}

export function documentColumn(params) {
  if (params.value.length === 14) {
    return RetornaCNPJormatado(params.value);
  } else {
    return RetornaCPFFormatado(params.value);
  }
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const ETipoDiario_Remessa = 0;
export const ETipoDiario_Renegociacao = 1;
export const ETipoDiario_FIDC = 3;

export const ETipoArquivo_CNAB240 = 0;
export const ETipoArquivo_CNAB400 = 1;
export const ETipoArquivo_CNAB500 = 4;
export const ETipoArquivo_Outro = 3;

export const ESelectionType_Multiple = 0;
export const ESelectionType_Single = 1;

export const ETipoDiario_Description = (tipoDiario) => {
  switch (ETipoDiario_GetEnum(tipoDiario)) {
    case ETipoDiario_Remessa:
      return 'Registro';
    case ETipoDiario_Renegociacao:
      return 'Instrução';
    case ETipoDiario_FIDC:
      return 'FIDC';
    default:
      return 'Desconhecido';
  }
}

export const ETipoDiario_GetEnum = (tipoDiario) => {
  switch (tipoDiario) {
    case "0":
      return ETipoDiario_Remessa;
    case "1":
      return ETipoDiario_Renegociacao;
    case "3":
      return ETipoDiario_FIDC;
    case 0:
      return ETipoDiario_Remessa;
    case 1:
      return ETipoDiario_Renegociacao;
    case 3:
      return ETipoDiario_FIDC;
    default:
      return ETipoArquivo_Outro;
  }
}

export const ETipoArquivo_GetEnum = (tipoArquivo) => {
  switch (tipoArquivo) {
    case "0":
      return ETipoArquivo_CNAB240;
    case "1":
      return ETipoArquivo_CNAB400;
    case "4":
      return ETipoArquivo_CNAB500;
    case 0:
      return ETipoArquivo_CNAB240;
    case 1:
      return ETipoArquivo_CNAB400;
    case 4:
      return ETipoArquivo_CNAB500;
    default:
      return ETipoDiario_Remessa;
  }
}

export const ETipoArquivo_Description = (tipoArquivo) => {
  switch (ETipoArquivo_GetEnum(tipoArquivo)) {
    case ETipoArquivo_CNAB240:
      return 'CNAB240';
    case ETipoArquivo_CNAB400:
      return 'CNAB400';
    case ETipoArquivo_CNAB500:
      return 'CNAB500 (FIDC)';
    default:
      return 'Outro';
  }
}

export const OcorrenciaInstrucaoModel = {
  key: 0,
  text: "Selecionar",
  ID: 0,
  TipoPrimitivo: "REM",
  CodOcorrencia: "",
  TextOcorrencia: "",
  GrupoBancario_NumBc: 0,
  Layout: 0,
  Valor: null,
  ValorAtribuido: null
}

export const OcorrenciaInstrucaoArrayModel = [OcorrenciaInstrucaoModel];

export const IsDateTypeValue = (value) => {
  if (value === 'Dt_Vencimento'|| value === 'Dt_Agendamento') {
    return true;
  }

  return false;
}

export const OcorrenciasDiarioModel = {
  OcorrenciaTituloDiario_ID: 0,
  ID: 0,
  Codigo: "",
  Default: "",
  Texto: "",
  Valor: "",
}